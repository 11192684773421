import { IPagination } from '../../types/types';
import * as actionTypes from './actionTypes';

import {
  CreateNewsAction,
  CreateNewsActionSuccess,
  CreateNewsActionFailed,
  CreateNewsPayload,
  CreateNewsPayloadSuccess,
  FetchNewsAction,
  FetchNewsActionError,
  FetchNewsActionSuccess,
  FetchNewsErrorPayload,
  FetchNewsSuccessPayload,
  UpdateNewsAction,
  UpdateNewsActionSuccess,
  UpdateNewsActionFailed,
  UpdateNewsPayload,
  UpdateNewsPayloadSuccess,
} from './types';

export const fetchNews = (pagination: IPagination): FetchNewsAction => ({
  type: actionTypes.FETCH_NEWS,
  pagination,
});

export const fetchNewsSuccess = (payload: FetchNewsSuccessPayload): FetchNewsActionSuccess => ({
  type: actionTypes.FETCH_NEWS_SUCCESS,
  payload,
});

export const fetchNewsError = (payload: FetchNewsErrorPayload): FetchNewsActionError => ({
  type: actionTypes.FETCH_NEWS_ERROR,
  payload,
});

export const createNews = (payload: CreateNewsPayload): CreateNewsAction => ({
  type: actionTypes.CREATE_NEWS,
  payload,
});

export const createNewsSuccess = (payload: CreateNewsPayloadSuccess): CreateNewsActionSuccess => ({
  type: actionTypes.CREATE_NEWS_SUCCESS,
  payload,
});

export const createNewsFailed = (): CreateNewsActionFailed => ({
  type: actionTypes.CREATE_NEWS_FAILED,
});

export const updateNews = (payload: UpdateNewsPayload): UpdateNewsAction => ({
  type: actionTypes.UPDATE_NEWS,
  payload,
});

export const updateNewsSuccess = (payload: UpdateNewsPayloadSuccess): UpdateNewsActionSuccess => ({
  type: actionTypes.UPDATE_NEWS_SUCCESS,
  payload,
});

export const updateNewsFailed = (): UpdateNewsActionFailed => ({
  type: actionTypes.UPDATE_NEWS_FAILED,
});
