/* eslint-disable import/prefer-default-export */
import { AxiosResponse } from 'axios';

import axiosInstance from '../config/axios';
import BACK_URL from '../utils/urls';

import { INews, INewsResponse, IUpdateNews } from '../types/news';
import { IPagination } from '../types/types';

const fetchNews = (pagination: IPagination): Promise<AxiosResponse<INewsResponse>> =>
  axiosInstance.get(`${BACK_URL}/news`, {
    params: pagination ? { limit: pagination.limit, page: pagination.page } : null,
  });

const createNews = (data: INews): Promise<AxiosResponse<INewsResponse>> =>
  axiosInstance.post(`${BACK_URL}/news`, data);

const updateNews = (data: IUpdateNews, id: string): Promise<AxiosResponse<INewsResponse>> =>
  axiosInstance.patch(`${BACK_URL}/news/${id}`, data);

export default { fetchNews, createNews, updateNews };
