import styled from 'styled-components';

const PreviewContainer = styled.div`
  height: 100%;
  margin: 20px;
  img {
    display: block;
    max-width: 400px;
    max-height: 400px;
    width: auto;
    height: auto;
  }
`;

export default PreviewContainer;
