/* eslint-disable react/prop-types */
import React from 'react';
import { Modal, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import EditNewsForm from '../../forms/editNewsForm';

import { updateNews } from '../../../store/news/actions';

import { INews, IUpdateNews } from '../../../types/news';
import { getDataPendingCreateOrUpdateSelector } from '../../../store/table/selector';

interface IEditNewsModalProps {
  visible: boolean;
  onCancel: () => void;
  news: INews | null;
}

const EditNewsModal: React.FunctionComponent<IEditNewsModalProps> = ({
  visible,
  onCancel,
  news,
}) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const pendingCreate = useSelector(state => getDataPendingCreateOrUpdateSelector(state, 'news'));

  React.useEffect(() => {
    form.resetFields();
  }, [visible]);

  const onSubmit = (values: IUpdateNews) => {
    // eslint-disable-next-line no-underscore-dangle
    if (news && news._id) dispatch(updateNews({ news: values, callback: onCancel, id: news._id }));
  };

  return (
    <Modal visible={visible} title="Update news" footer={null} onCancel={onCancel}>
      {news ? (
        <EditNewsForm form={form} onSubmit={onSubmit} pending={pendingCreate} news={news} />
      ) : null}
    </Modal>
  );
};

export default EditNewsModal;
