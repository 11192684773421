/* eslint-disable react/button-has-type */
import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import DataTable from '../components/tables';
import CreateNewsModal from '../components/modals/createNewsModal';
import EditNewsModal from '../components/modals/editNewsModal';
import { PageLayout } from '../components/layout/page';
import IntlMessages from '../utils/intlMessages/intlMessages';

import { fetchNews } from '../store/news/actions';
import { INews } from '../types/news';

const columns = [
  {
    title: 'ID',
    dataIndex: '_id',
    key: '_id',
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Content',
    dataIndex: 'content',
    key: 'content',
    ellipsis: true,
  },
  {
    title: 'Domain',
    dataIndex: 'domain',
    key: 'domain',
  },
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt = '') => dayjs(createdAt).format('H:mm:ss DD.MM.YYYY'),
  },
];

const EditorContainer = styled.div`
  display: flex;
  flex: 1 1 0px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0 5% 20px;
  max-height: 40vh;
  overflow: auto;
`;

const NewsPage: React.FunctionComponent = () => {
  const [selectedNews, updateSelectedNews] = React.useState<INews | null>(null);

  const [createModalVisible, updateCreateModalVisible] = React.useState(false);

  const onCancel = () => {
    updateSelectedNews(null);
  };

  return (
    <PageLayout>
      <EditorContainer>
        <div>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => updateCreateModalVisible(true)}
            icon={<PlusOutlined />}
          >
            <IntlMessages id="departments.page.addNewDepartment" />
          </Button>
        </div>
      </EditorContainer>
      <DataTable
        columns={columns}
        fetchData={fetchNews}
        dataType="news"
        onRowClick={(record: INews) => updateSelectedNews(record)}
      />
      <CreateNewsModal
        visible={createModalVisible}
        onCancel={() => updateCreateModalVisible(false)}
      />
      <EditNewsModal news={selectedNews} onCancel={onCancel} visible={!!selectedNews} />
    </PageLayout>
  );
};

export default NewsPage;
