export const FETCH_NEWS = 'FETCH_NEWS';
export const FETCH_NEWS_SUCCESS = `${FETCH_NEWS}_SUCCESS`;
export const FETCH_NEWS_ERROR = `${FETCH_NEWS}_ERROR`;

export const CREATE_NEWS = 'CREATE_NEWS';
export const CREATE_NEWS_SUCCESS = `${CREATE_NEWS}_SUCCESS`;
export const CREATE_NEWS_FAILED = `${CREATE_NEWS}_FAILED`;

export const UPDATE_NEWS = 'UPDATE_NEWS';
export const UPDATE_NEWS_SUCCESS = `${UPDATE_NEWS}_SUCCESS`;
export const UPDATE_NEWS_FAILED = `${UPDATE_NEWS}_FAILED`;
