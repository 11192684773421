/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import { CREATE_NEWS, FETCH_NEWS, UPDATE_NEWS } from './actionTypes';

import { INewsResponse, ISingleNewsResponse } from '../../types/news';

import NewsService from '../../services/NewsService';
import { uploadFile } from '../../services/FileService';
import {
  createNewsSuccess,
  fetchNewsError,
  fetchNewsSuccess,
  createNewsFailed,
  updateNewsFailed,
  updateNewsSuccess,
} from './actions';
import { CreateNewsAction, FetchNewsAction, UpdateNewsAction } from './types';
import { IFile } from '../../types/file';

function* fetchNewsSaga(action: FetchNewsAction) {
  try {
    const response: AxiosResponse<INewsResponse> = yield call(
      NewsService.fetchNews,
      action.pagination
    );
    yield put(
      fetchNewsSuccess({
        news: response?.data?.data,
        pagination: { ...action.pagination, total: +response.headers['total-elements'] },
      })
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    yield put(fetchNewsError({ error: error.message }));
  }
}

function* createNewsSaga(action: CreateNewsAction) {
  try {
    const fileResponse: AxiosResponse<{ status: 'success'; data: IFile }> = yield call(
      uploadFile,
      action.payload.news.image
    );

    const { title, content, domain } = action.payload.news;

    const response: AxiosResponse<ISingleNewsResponse> = yield call(NewsService.createNews, {
      title,
      content,
      domain,
      // eslint-disable-next-line no-underscore-dangle
      images: [fileResponse.data.data._id],
    });

    yield put(createNewsSuccess({ news: response.data.data }));
    // case when there's callback function
    if (action.payload.callback) action.payload.callback();
  } catch (error: any) {
    yield put(createNewsFailed());
    message.error(error);
  }
}

function* updateNewsSaga(action: UpdateNewsAction) {
  try {
    let fileResponse: AxiosResponse<{ status: 'success'; data: IFile }> | undefined;

    if (action?.payload?.news?.image) {
      fileResponse = yield call(uploadFile, action.payload.news.image);
    }

    const { title, content, domain } = action.payload.news;

    const response: AxiosResponse<ISingleNewsResponse> = yield call(
      NewsService.updateNews,
      {
        title,
        content,
        domain,
        // eslint-disable-next-line no-underscore-dangle
        ...(fileResponse && { images: [fileResponse.data.data._id] }),
      },
      action.payload.id
    );

    yield put(updateNewsSuccess({ news: response.data.data }));
    // case when there's callback function
    if (action.payload.callback) action.payload.callback();
  } catch (error: any) {
    yield put(updateNewsFailed());
    message.error(error);
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* newsSaga() {
  yield all([
    takeLatest(FETCH_NEWS, fetchNewsSaga),
    takeLatest(CREATE_NEWS, createNewsSaga),
    takeLatest(UPDATE_NEWS, updateNewsSaga),
  ]);
}

export default newsSaga;
