/* eslint-disable react/prop-types */
import React from 'react';
import { Modal, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import CreateNewsForm from '../../forms/createNewsForm';

import { createNews } from '../../../store/news/actions';

import { ICreateNews } from '../../../types/news';
import { getDataPendingCreateOrUpdateSelector } from '../../../store/table/selector';

interface INewsModalProps {
  visible: boolean;
  onCancel: () => void;
}

const CreateNewsModal: React.FunctionComponent<INewsModalProps> = ({ visible, onCancel }) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [preview, updatePreview] = React.useState(false);

  const pendingCreate = useSelector(state => getDataPendingCreateOrUpdateSelector(state, 'news'));

  React.useEffect(() => {
    form.resetFields();
    updatePreview(prev => !prev);
  }, [visible]);

  const onSubmit = (values: ICreateNews) => {
    dispatch(createNews({ news: values, callback: onCancel }));
  };

  return (
    <Modal visible={visible} title="Create News" footer={null} onCancel={onCancel}>
      <CreateNewsForm
        form={form}
        onSubmit={onSubmit}
        resetPreview={preview}
        pending={pendingCreate}
      />
    </Modal>
  );
};

export default CreateNewsModal;
