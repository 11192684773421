import { AxiosResponse } from 'axios';

import axiosInstance from '../config/axios';
import BACK_URL from '../utils/urls';

import { IFile } from '../types/file';

// eslint-disable-next-line import/prefer-default-export
export const uploadFile = (file: File): Promise<AxiosResponse<{ status: string; data: IFile }>> => {
  const formData = new FormData();
  formData.append('name', file.name);
  formData.append('file', file);
  return axiosInstance.post(`${BACK_URL}/files/upload`, formData);
};
