import styled from 'styled-components';

import Colors from '../../utils/colors/colors';

const FormContainer = styled.div`
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    content: 'Select some image';
    display: inline-block;
    background: linear-gradient(top, ${Colors.lightGrey}, ${Colors.darkGrey});
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }
  .custom-file-input:hover::before {
    border-color: black;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, ${Colors.darkGrey}, ${Colors.lightGrey});
  }
`;

export default FormContainer;
