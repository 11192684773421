/* eslint-disable no-underscore-dangle */
import { INews } from '../../types/news';
import {
  CREATE_NEWS,
  CREATE_NEWS_FAILED,
  CREATE_NEWS_SUCCESS,
  FETCH_NEWS,
  FETCH_NEWS_ERROR,
  FETCH_NEWS_SUCCESS,
  UPDATE_NEWS,
  UPDATE_NEWS_SUCCESS,
  UPDATE_NEWS_FAILED,
} from './actionTypes';

import {
  CreateNewsActionSuccess,
  FetchNewsActionError,
  FetchNewsActionSuccess,
  NewsAction,
  UpdateNewsActionSuccess,
} from './types';

const initialState = {
  pending: false,
  news: [],
  error: null,
  pagination: {
    limit: 10,
    page: 1,
    total: 50,
  },
  pendingCreateOrUpdate: false,
};

// eslint-disable-next-line
export default (state = initialState, action: NewsAction) => {
  switch (action.type) {
    case FETCH_NEWS: {
      return {
        ...state,
        pending: true,
      };
    }
    case FETCH_NEWS_SUCCESS: {
      return {
        ...state,
        pending: false,
        news: (action as FetchNewsActionSuccess).payload.news,
        pagination: (action as FetchNewsActionSuccess).payload.pagination,
      };
    }
    case FETCH_NEWS_ERROR: {
      return {
        ...state,
        pending: false,
        news: [],
        error: (action as FetchNewsActionError).payload.error,
      };
    }
    case CREATE_NEWS: {
      return {
        ...state,
        pendingCreateOrUpdate: true,
      };
    }
    case CREATE_NEWS_SUCCESS: {
      return {
        ...state,
        news: [(action as CreateNewsActionSuccess).payload.news, ...state.news],
        pagination: {
          ...state.pagination,
          total: state.pagination.total + 1,
        },
        pendingCreateOrUpdate: false,
      };
    }
    case CREATE_NEWS_FAILED: {
      return {
        ...state,
        pendingCreateOrUpdate: false,
      };
    }

    case UPDATE_NEWS: {
      return {
        ...state,
        pendingCreateOrUpdate: true,
      };
    }
    case UPDATE_NEWS_SUCCESS: {
      return {
        ...state,
        news: state.news.map((singleNews: INews) => {
          const updatedNews = (action as UpdateNewsActionSuccess).payload.news;
          if (updatedNews._id === singleNews._id) return updatedNews;
          return singleNews;
        }),
        pendingCreateOrUpdate: false,
      };
    }
    case UPDATE_NEWS_FAILED: {
      return {
        ...state,
        pendingCreateOrUpdate: false,
      };
    }
    default:
      return state;
  }
};
