export const GET_ALL_DEPARTMENTS_REQUEST = 'GET_ALL_DEPARTMENTS_REQUEST';
export const GET_ALL_DEPARTMENTS_SUCCESS = 'GET_ALL_DEPARTMENTS_SUCCESS';
export const NEW_DEPARTMENT_REQUEST = 'NEW_DEPARTMENT_REQUEST';
export const NEW_DEPARTMENT_REQUEST_SUCCESS = 'NEW_DEPARTMENT_REQUEST_SUCCESS';
export const GET_DEPARTMENT_BY_ID_REQUEST = 'GET_DEPARTMENT_BY_ID_REQUEST';
export const GET_DEPARTMENT_BY_ID_SUCCESS = 'GET_DEPARTMENT_BY_ID_SUCCESS';
export const UPDATE_DEPARTMENT_REQUEST = 'UPDATE_DEPARTMENT_REQUEST';
export const UPDATE_DEPARTMENT_SUCCESS = 'UPDATE_DEPARTMENT_SUCCESS';
export const DELETE_DEPARTMENT_REQUEST = 'DELETE_DEPARTMENT_REQUEST';
export const DELETE_DEPARTMENT_SUCCESS = 'DELETE_DEPARTMENT_SUCCESS';
export const FETCH_ALL_DEPARTMENTS_REQUEST = 'FETCH_ALL_DEPARTMENTS_REQUEST';
export const FETCH_ALL_DEPARTMENTS_SUCCESS = 'FETCH_ALL_DEPARTMENTS_SUCCESS';
