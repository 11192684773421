const Colors = {
  white: '#fff',
  instructionColor: '#a9a9a9',
  titleColor: '#303942',
  linkTextColor: '#1890ff',
  lightGrey: '#f9f9f9',
  darkGrey: '#e3e3e3',
};

export default Colors;
