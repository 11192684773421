/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React from 'react';
import { Form, Button, Input, FormInstance } from 'antd';
import FormContainer from '../../common/form-container';
import PreviewContainer from '../../common/preview-container';

import { getBase64 } from '../../../utils/file-utils';

import { ICreateNews } from '../../../types/news';

interface ICreateNewsFormProps {
  form: FormInstance;
  onSubmit?: (values: ICreateNews) => void;
  resetPreview: boolean;
  pending: boolean;
}

const CreateNewsForm: React.FunctionComponent<ICreateNewsFormProps> = ({
  form,
  onSubmit,
  resetPreview,
  pending = false,
}) => {
  const [imgUrl, updateImgUrl] = React.useState('');

  const [file, updateFile] = React.useState<null | File>(null);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    updateFile(event.target.files[0]);
    getBase64(event.target.files[0], (file: string) => {
      updateImgUrl(file);
    });
  };

  React.useEffect(() => {
    updateImgUrl('');
  }, [resetPreview]);

  const onFinish = async (values: { title: string; content: string; domain: string }) => {
    if (file && onSubmit) {
      onSubmit({
        title: values.title,
        content: values.content,
        domain: values.domain,
        image: file,
      });
    }
  };

  return (
    <FormContainer>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              required: true,
              message: 'Please input title',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Domain"
          name="domain"
          rules={[
            {
              required: true,
              message: 'Please input domain',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Content"
          name="content"
          rules={[
            {
              required: true,
              message: 'Please input content',
            },
          ]}
        >
          <Input.TextArea rows={5} />
        </Form.Item>
        <Form.Item label="Image" name="image">
          <Input
            type="file"
            className="custom-file-input"
            onChange={changeHandler}
            accept="image/*"
          />
          <PreviewContainer>
            <img src={imgUrl} alt="news" />
          </PreviewContainer>
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={pending}>
          Add news
        </Button>
      </Form>
    </FormContainer>
  );
};

export default CreateNewsForm;
