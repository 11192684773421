/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React from 'react';
import { Form, Button, Input, FormInstance } from 'antd';
import FormContainer from '../../common/form-container';
import PreviewContainer from '../../common/preview-container';

import { INews, Image, IUpdateNews } from '../../../types/news';

import { getBase64 } from '../../../utils/file-utils';

interface IEditNewsFormProps {
  form: FormInstance;
  onSubmit?: (values: IUpdateNews) => void;
  pending: boolean;
  news: INews;
}

const EditNewsForm: React.FunctionComponent<IEditNewsFormProps> = ({
  form,
  onSubmit,
  pending = false,
  news: { content, domain, title, images },
}) => {
  const [imgUrl, updateImgUrl] = React.useState('');

  const [file, updateFile] = React.useState<null | File>(null);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    updateFile(event.target.files[0]);
    getBase64(event.target.files[0], (file: string) => {
      updateImgUrl(file);
    });
  };

  const onFinish = async (values: { title: string; content: string; domain: string }) => {
    if (onSubmit)
      onSubmit({
        title: values.title,
        domain: values.domain,
        content: values.content,
        image: file,
      });
  };

  React.useEffect(() => {
    form.setFieldsValue({
      title,
      content,
      domain,
    });
    updateImgUrl((images as Image[])?.[0]?.url);
    updateFile(null);
  }, [title, content, domain, images]);

  return (
    <FormContainer>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{ title, content, domain }}
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              required: true,
              message: 'Please input title',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Domain"
          name="domain"
          rules={[
            {
              required: true,
              message: 'Please input domain',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Content"
          name="content"
          rules={[
            {
              required: true,
              message: 'Please input content',
            },
          ]}
        >
          <Input.TextArea rows={5} />
        </Form.Item>
        <Form.Item label="Image" name="image">
          <Input
            type="file"
            className="custom-file-input"
            onChange={changeHandler}
            accept="image/*"
          />
          <PreviewContainer>
            <img src={imgUrl} alt="news" />
          </PreviewContainer>
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={pending}>
          Update news
        </Button>
      </Form>
    </FormContainer>
  );
};

export default EditNewsForm;
